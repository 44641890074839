
import { defineComponent, ref } from "vue";
import Notice from "@/components/Notice.vue";

export default defineComponent({
  name: "kt-customer",
  components: {
    Notice,
  },
  setup() {
    const body = ref(
      `Writing headlines for blog posts is much science and probably cool audience. <a href="javascript:void(0)" class="fw-bolder">Learn more</a>.`
    );

    return {
      body,
    };
  },
});
